<template>
  <div class="about">
    <h1>This is an about page</h1>
    <p>Found: {{fruit}}</p>
    <autocomplete-search
        :value-field="'id'"
        :display-field="'name'"
        :search-results-view="displayAs"

        :items="items"
        :placeholder="'Выбрать фрукт'"
        v-model="fruit"
    />
  </div>

</template>

<script>
import AutocompleteSearch from '@/components/AutocompleteSearch.vue'

export default {
  name: 'App',
  components: {
    AutocompleteSearch
  },
  data() {
    return {
      fruit: null,
      items: [
        {id:1, name:'Apple'},
        {id:2, name:'Banana'},
        {id:3, name:'Orange'},
        {id:4, name:'Mango'},
        {id:5, name:'Pear'},
        {id:6, name:'Peach'},
        {id:7, name:'Grape'},
        {id:8, name:'Tangerine'},
        {id:9, name:'Pineapple'}
      ]
    }
  },
  methods: {
    displayAs(item) {
      return `${item.id} - ${item.name}`
    }
  }
}
</script>

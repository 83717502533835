<template>
  <div class="autocomplete">
    <input
        type="text"
        v-model="displaySearch"
        @input="onChange"
        @keydown.down="onArrowDown"
        @keydown.up="onArrowUp"
        @keydown.enter="onEnter"
        @focusin="onFocusIn"
        :placeholder="placeholder"
    />
    <ul ref="acResults"
        v-show="isOpen"
        class="autocomplete-results"
    >
      <li
          v-for="(result, i) in results"
          :key="i"
          @click="setResult(result)"
          class="autocomplete-result"
          :class="{ 'is-active': i === arrowCounter }"
      >
        {{ renderItem(result) }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'autocomplete-search',
  prop: ['value'],
  model: {
    prop: 'value',
    event: 'blur'
  },
  props: {
    value:{default: null},
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    placeholder: {type: String, required: false, default: ''},
    valueField:{type: String, required: false, default: null},
    displayField:{type: String, required: false, default: null},
    searchResultsView:{type: Function, required: false, default: null}
  },
  data() {
    return {
      search: null,
      displaySearch: '',
      results: [],
      isOpen: false,
      arrowCounter: -1
    };
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    filterResults() {
      if (this.displayField && this.valueField) {
        this.results = this.items.
        filter(item => {
          var v;
          if (null != this.searchResultsView) {
            v = this.searchResultsView(item)
          } else {
            v =item[this.displayField]
          }
          return v.toLowerCase().indexOf(this.displaySearch.toLowerCase()) > -1
        })
      } else {
        this.results = this.items.filter(item => item.toLowerCase().indexOf(this.search.toLowerCase()) > -1);
      }

      if (this.results.length==0) {
        this.search = null
      }

    },
    onChange() {
      this.filterResults();
      this.isOpen = true;
    },
    setResult(result) {
      this.search = result;
      this.isOpen = false;
    },
    onArrowDown() {
      if (this.arrowCounter==-1 && this.items.length >0) {
        if (!this.displaySearch) {
          this.results= this.items
        } else {
          this.filterResults();
        }
        this.isOpen = true;
      }

      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }

      this.scrollResults()
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
      this.scrollResults()
    },
    onFocusIn() {
      this.onChange()
      this.scrollResults()
    },
    onEnter() {
      this.search = this.results[this.arrowCounter];
      this.arrowCounter = -1;
      this.isOpen = false;
    },
    handleClickOutside() {
      if (!this.$el.contains(event.target)) {
        this.arrowCounter = -1;
        this.isOpen = false;
      }

      if (!this.displaySearch) {
        this.search = null;
      }

      if (this.search != null) {
        if (this.displayField && this.valueField) {
          this.displaySearch = this.search[this.displayField]
        } else {
          this.displaySearch = this.search
        }
      }
    },
    scrollResults(){
      var sc = this.arrowCounter * 32;
      this.$refs.acResults.scroll(0, sc)
    },
    renderItem(item) {

      if(null != this.searchResultsView) {
        return this.searchResultsView(item);
      } else

      if (this.displayField) {
        return item[this.displayField];
      }
      return item;
    }
  },
  watch:{
    search(newItem) {
      this.$emit('blur', newItem)
      if (null==newItem) {
        this.displaySearch = ''
        return;
      }

      if (newItem && this.displayField) {
        this. displaySearch = newItem[this.displayField];
      } else {
        this.displaySearch = newItem
      }
    }
  }
};
</script>

<style>
.autocomplete {
  position: relative;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  min-height: 1em;
  max-height: 6em;
  overflow: auto;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}
.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #4AAE9B;
  color: white;
}
</style>